import React, {useState} from 'react';
import APIUtils from '../../utils/APIUtils';

export type UserModalType = {
  showUserModal: () => void;
  hideUserModal: () => void;
  addUser: () => void;
  showModal: boolean;
};

const UserModal: React.FC<UserModalType> = props => {

  const [userEmail, setUserEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);

  const sendUserInvite = async () => {
    if(userEmail === '') {
      setErrorMessage(true);
      return setTimeout(() => setErrorMessage(false), 3000);
    }

    try {
      let res = await APIUtils.callPost('api/user/invite', {email: userEmail})
      if (res.status !== 200) {
        return console.error('Error Creating Client');
      }
      setInviteSent(true);
      props.addUser();
    } catch(err) {
      console.error(err);
    }
  }

  const handleCloseModal = () => {
    props.hideUserModal();
    setInviteSent(false);
  }
  
  return (
    <>
    {props.showModal && (
      <div className="modal userModal">
        <div className="modalWrap">
          <section className="contentWrap">
            {inviteSent && (
                <>
                    <h2>Success!</h2>
                    <p>An invite has been sent to {userEmail}</p>
                    <button className="cancelBtn" onClick={() => handleCloseModal()}>Close</button>
                </>
            )}
            {!inviteSent && (
                <>
                    <h2>Invite User</h2>
                    { errorMessage && (
                        <div className="alert error">A valid email is required.</div>
                    )}
                    <div className="field">
                        <label htmlFor="Email">Email Address</label>
                        <input type="text" onChange={(e) => setUserEmail(e.target.value)} placeholder="Email" />
                    </div>
                    <div className="btnGroup">
                        <button className="cancelBtn" onClick={() => props.hideUserModal()}>Cancel</button>
                        <button onClick={sendUserInvite}>
                        + Invite
                        </button>
                    </div>
                </>
            )}
          </section>
        </div>
      </div>
    )}
    </>
  )
}

export default UserModal;
