import React, {useState, useEffect, useRef} from 'react';
import {ActiveClientType} from '../DataPoints/DataModal';
import { Types } from 'mongoose';

import trash from '../../assets/img/trash.svg';
import copy from '../../assets/img/copy.svg';

export type Client = {
  _id: Types.ObjectId;
  name: string;
  dataPoints: Object[];
  delete: (id: any, clientName: string) => void;
  onClientClick: (client: ActiveClientType) => void;
};

const displayClientID = (id: string) => {
  const first6 = id.substring(0, 6);
  return first6+'...';
}

const ClientListItem: React.FC<Client> = props => {
  const deleteRef = useRef() as React.MutableRefObject<HTMLButtonElement>;
  const copyRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  const [showCopied, setShowCopied] = useState<boolean>(false);

  const onRowClick = (target: EventTarget) => {
    if(target !== deleteRef.current && target !== copyRef.current) {
      props.onClientClick(props);
    }
  }

  const handleCopyClientID = () => {
    navigator.clipboard.writeText(props._id.toString());
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 500);
  }

  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 768);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <section className="listingItem" onClick={(e) => onRowClick(e.target)}>
      <article className="capitalize">{props.name}</article>
      <article className="clientID">
        {isMobile ? (displayClientID(props._id.toString())) : (props._id.toString())}
        <img ref={copyRef} onClick={handleCopyClientID} src={copy} alt="Copy Client ID" />
        {showCopied && (<span>copied</span>)}
      </article>
      <article>{props.dataPoints.length ? props.dataPoints.length : "0"}</article>
      <article>
        <button ref={deleteRef} className="trashBtn" onClick={() => props.delete(props._id, props.name)}>
          <img src={trash} alt="Delete" />
        </button>
      </article>
    </section>
  )
};

export default ClientListItem;
