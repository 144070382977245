import React, {useState, useRef, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';
import DataListItem, {DataPoint} from './DataPoint';
import { Types } from 'mongoose';

export type ActiveClientType = {
  _id: Types.ObjectId;
  name?: String;
  dataPoints: Object[] | [];
}

export type DataModalType = {
  showDataModal: (value: boolean) => void;
  activeClient: ActiveClientType;
  showModal: boolean;
};

const DataModal: React.FC<DataModalType> = props => {
  const listingRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const [id, setClientId] = useState<Types.ObjectId>();
  const [title, setTitle] = useState('');
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  const [activeClientDataPoints, setClientDataPoints] = useState<any[]>([{title:'', coordinates: {lat: 0, lng: 0}}]);

  const addDataPoint = () => {
    let data = {
      clientId: id,
      title: title,
      lat: lat,
      lng: lng
    }
    if(title === '' || lat === '' || lng === '') {
      setErrorMessage(true);
      return setTimeout(() => setErrorMessage(false), 3000);
    }
    APIUtils.callPost('api/datapoint/create', data)
    .then(response => {
      if (response.status !== 200) {
        return console.error("Error Creating Data Point");
      }
      setClientDataPoints(response.data.dataPoints);
      clearFormInputs();
    });
  }

  const clearFormInputs = () => {
    setTitle('');
    setLat('');
    setLng('');
  }

  const updateActiveClientDataPoints = (dataPoints: DataPoint[]) => {
    setClientDataPoints(dataPoints);
  }

  useEffect(() => {
    setClientId(props.activeClient._id);
    setClientDataPoints(props.activeClient.dataPoints);
  }, [props]);

  return (
    <>
    {props.showModal && (
      <div className="modal dataModal">
        <div className="modalWrap">
          <section className="contentWrap">
            <h2>{activeClientDataPoints.length} Data Points for <span className="capitalize">{props.activeClient.name}</span></h2>
            {errorMessage && (
              <div className="alert error">All data point values are required.</div>
            )}
            <div className="dataPointsForm">
              <div className="field">
                <label htmlFor="title">Title</label>
                <input type="text" name="title" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)}/>
              </div>

              <div className="field">
                <label htmlFor="title">Latitude</label>
                <input type="text" name="latitude" value={lat} placeholder="Latitude" onChange={(e) => setLat(e.target.value)} />
              </div>

              <div className="field">
                <label htmlFor="title">Longitude</label>
                <input type="text" name="longitude" value={lng} placeholder="Longitude" onChange={(e) => setLng(e.target.value)} />
              </div>
              <button onClick={addDataPoint}>Add</button>
            </div>
            <div className="table dataPointsList">
              { activeClientDataPoints.length > 0 ? (
                <>
                <section className="titles">
                  <article>Title</article>
                  <article>Latitude</article>
                  <article>Longitude</article>
                  <article></article>
                </section>
                <div ref={listingRef} className="listResults">
                  {activeClientDataPoints.map((point: any, index: number) => {
                    return (
                      <DataListItem 
                        {...point}
                        key={index}
                        clientId={props.activeClient._id}
                        updateDataPoints={(newDataPoints: DataPoint[]) => updateActiveClientDataPoints(newDataPoints)}
                      />
                    );
                  })}
                </div>
                </>
              ) : (
                <div className="alert">
                  There are currently no data points for this client install. Use the form above to add them.
                </div>
              )}
            </div>
            <button className="cancelBtn" onClick={() => props.showDataModal(false)}>Close</button>
          </section>
        </div>
      </div>
      )
    }
    </>
  )
}

export default DataModal;
