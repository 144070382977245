import trash from '../../assets/img/trash.svg';

const UserListItem = (props: {
    user: any
    deleteUser: (user: any) => void
}) => {
    return (
        <section className="listingItem">
            <article>{props.user.email}</article>
            <article>{props.user.isActivated ? 'active' : 'pending'}</article>
            <article>
                <button className="trashBtn" onClick={() => props.deleteUser(props.user)}>
                    <img src={trash} alt="Delete" />
                </button>
            </article>
        </section>
    )
}

export default UserListItem
