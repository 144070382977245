import ClientListItem, {Client} from './ClientListItem';
import { Types } from 'mongoose';
import {ActiveClientType} from '../DataPoints/DataModal';

const ClientListing = (props: {
    clientList: Client[]
    confirmDeleteClient: (id: any, clientName: string) => void
    onClientClick: (client: ActiveClientType) => void
    showClientModal: () => void
}) => {
    return (
        <div className="contentWrap">

          {props.clientList.length !== 0 ? (
            <div className="table clients">
              <section className="titles">
                <article>Client</article>
                <article>ID</article>
                <article># Data Points</article>
                <article></article>
              </section>
              <div className="listing">
                {props.clientList.map((client: Client, index: number) => {
                  return (
                    <ClientListItem
                      {...client}
                      key={index}
                      delete={(id: Types.ObjectId, clientName: string) => props.confirmDeleteClient(id, clientName)}
                      onClientClick={(client: ActiveClientType) => props.onClientClick(client)}
                    />);
                })}
              </div>
            </div>
            ) : (
              <div className="alert">
                No clients created. Click the button to add new client.
              </div>
            )
          }

          <button className="addBtn" onClick={props.showClientModal}>+ Add Client</button>

        </div>
    )
}

export default ClientListing
