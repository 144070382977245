// import md5 from 'md5';
import {
  useState,
  useEffect
} from 'react';
import APIUtils from '../utils/APIUtils';
import NavUtils from '../utils/NavUtils';

import Logo from '../assets/img/logo.jpg';
import loginImage from '../assets/img/loginbg.jpg';

const Login = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [loginError, setLoginError] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    const login = async () => {
        setIsLoading(true);

        let isValid = loginFieldValidation()
        if (!isValid) return;

        let params = {
            email: loginEmail,
            password: loginPassword
        };

        let res = await APIUtils.callPost('api/account/login', params);
        
        if(res.status !== 200) {
            setIsLoading(false)
            return setLoginError(res.message)
        }

        NavUtils.redirectToDashboard()
	}

	const loginFieldValidation = () => {

		if(!loginEmail) {
            setIsLoading(false)
            setLoginError('Please enter a valid email address.')
            return false
		}

        if(!loginPassword) {
            setIsLoading(false)
            setLoginError('Please enter your password.')
            return false
		}

        return true
	}

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    const handleKeypress = (e: any) => {
        if (e.keyCode === 13) {
          login();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

  return (
    <>
      <div id="Login">
        <section className="left">
          {!isMobile && (<img className="logo" src={Logo} alt="Logo" />)}
          <div className="loginFrmWrap">
            <div className="formWrap">
              <h1>Welcome</h1>
              {
                loginError && (
                  <p className="alert error">{loginError}</p>
                )
              }
              <div className="field">
                <label htmlFor="email">Email</label>
                <input 
                  type="email" 
                  name="email"
                  placeholder="email" 
                  value={loginEmail}
                  onChange={(e) => setLoginEmail(e.target.value)}
                />
              </div>
              <div className="field">
                <label htmlFor="password">
                  Password
                </label>
                <input 
                  type="password" 
                  name="password"
                  placeholder="password" 
                  value={loginPassword}
                  onChange={(e) => setLoginPassword(e.target.value)}
                  onKeyUp={handleKeypress}
                />
              </div>
              {
                (isLoading) ? (
                  <button onClick={login}><i className="loading">Loading...</i></button>
                ) : (
                  <button onClick={login}>Login</button>
                )
              }
            </div>
          </div>
        </section>
        <section className="right" style={{backgroundImage: `url(${loginImage})`}}>
          {isMobile && (<img className="logo" src={Logo} alt="Logo" />)}
        </section>
      </div>
    </>
  );
}

export default Login;
