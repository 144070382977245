import React, {useState} from 'react';
import APIUtils from '../../utils/APIUtils';
import {Client} from './ClientListItem';

export type ClientModalType = {
  showClientModal: (show: boolean) => void;
  addClient: (client: Client) => void;
  showModal: boolean;
};

const ClientModal: React.FC<ClientModalType> = props => {

  const [clientName, setClientName] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);

  const createClient = async () => {
    if(clientName === '') {
      setErrorMessage(true);
      return setTimeout(() => setErrorMessage(false), 3000);
    }

    try {
      let res = await APIUtils.callPost('api/client/create', {name: clientName})
      if (res.status !== 200) {
        return console.error('Error Creating Client');
      }
      props.addClient(res.data);
      props.showClientModal(false);
    } catch(err) {
      console.error(err);
    }
  }
  
  return (
    <>
    {props.showModal && (
      <div className="modal clientModal">
        <div className="modalWrap">
          <section className="contentWrap">
            <h2>Add Client</h2>
            {errorMessage && (
              <div className="alert error">A client name is required.</div>
            )}
            <div className="field">
              <label htmlFor="Name">Client Name</label>
              <input type="text" onChange={(e) => setClientName(e.target.value)} placeholder="Name" />
            </div>
            <div className="btnGroup">
              <button className="cancelBtn" onClick={() => props.showClientModal(false)}>Cancel</button>
              <button onClick={createClient}>
                + Create
              </button>
            </div>
          </section>
        </div>
      </div>
    )}
    </>
  )
}

export default ClientModal;
