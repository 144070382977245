// import md5 from 'md5';
import {
  useState,
  useEffect
} from 'react';
import { useLocation } from 'react-router-dom';
import APIUtils from '../utils/APIUtils';
import NavUtils from '../utils/NavUtils';

import Logo from '../assets/img/logo.jpg';
import loginImage from '../assets/img/loginbg.jpg';

const Register = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [registerError, setRegisterError] = useState('');
    // const [registerEmail, setRegisterEmail] = useState('');
    const [registerPassword, setRegisterPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const guid = queryParams.get('invite');

    const register = async () => {
        setIsLoading(true);

        let isValid = registerFieldValidation()
        if (!isValid) return;

        let params = {
            guid: guid,
            password: registerPassword
        };

        let res = await APIUtils.callPost('api/account/register', params)
        if(res.status !== 200) {
            setIsLoading(false)
            return setRegisterError(res.message)
        }
        NavUtils.redirectToRoot();
	}

	const registerFieldValidation = () => {
		if(!registerPassword) {
            setIsLoading(false)
            setRegisterError('Please enter your password.')
            return false
		}

        if(registerPassword !== confirmPassword) {
            setIsLoading(false)
            setRegisterError('Password does not match.')
            return false
		}

		// if(!registerEmail) {
        //     setIsLoading(false)
        //     setRegisterError('Please enter a valid email address.')
        //     return false
		// }

        return true
	}

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 768);
    }

    const handleKeypress = (e: any) => {
        if (e.keyCode === 13) {
            register();
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

  return (
    <>
      <div id="Login">
        <section className="left">
          {!isMobile && (<img className="logo" src={Logo} alt="Logo" />)}
          <div className="loginFrmWrap">
            <div className="formWrap">
              <h1>Register</h1>
              {
                registerError && (
                  <p className="alert error">{registerError}</p>
                )
              }
              {/* <div className="field">
                <label htmlFor="email">Email</label>
                <input 
                  type="email" 
                  name="email"
                  placeholder="email" 
                  value={registerEmail}
                  onChange={(e) => setRegisterEmail(e.target.value)}
                />
              </div> */}
              <div className="field">
                <label htmlFor="password">
                  Password
                </label>
                <input 
                  type="password" 
                  name="password"
                  placeholder="password" 
                  value={registerPassword}
                  onChange={(e) => setRegisterPassword(e.target.value)}
                />
              </div>
              <div className="field">
                <label htmlFor="password2">
                  Confirm Password
                </label>
                <input 
                  type="password" 
                  name="password2"
                  placeholder="confirm password" 
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onKeyUp={handleKeypress}
                />
              </div>
              {
                (isLoading) ? (
                  <button onClick={register}><i className="loading">Loading...</i></button>
                ) : (
                  <button onClick={register}>Set Password</button>
                )
              }
            </div>
          </div>
        </section>
        <section className="right" style={{backgroundImage: `url(${loginImage})`}}>
          {isMobile && (<img className="logo" src={Logo} alt="Logo" />)}
        </section>
      </div>
    </>
  );
}

export default Register;
