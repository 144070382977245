import {useState, useEffect} from 'react';
import APIUtils from '../../utils/APIUtils';

import Header from '../Header';
import ClientListing from './ClientListing';
import {Client} from './ClientListItem';
import ClientModal from './ClientModal';
import ClientDeleteModal from './ClientDeleteModal';
import DataModal, {ActiveClientType} from '../DataPoints/DataModal';

const Account = () => {
  const [showClientModal, setShowClientModal] = useState(false);
  const [showDataModal, setShowDataModal] = useState(false);

  const [deleteClientInfo, setDeleteClientInfo] = useState({id:'', clientName:''});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const [clientList, setClientList] = useState<Client[]>([]);
  const [activeClient, setActiveClient] = useState<any>({_id: '', name: '', dataPoints: []});

  const fetchClientList = () => {
    APIUtils.callGet('api/client/list')
    .then(response => {
      if (response.status !== 200) {
        return console.error("Error Fetching Client List");
      }
      setClientList(response.data);
    });
  }

  const confirmDeleteClient = (id: any, clientName: string) => {
    setDeleteClientInfo({id: id, clientName: clientName});
    setShowDeleteConfirmation(true);
  }

  const deleteClient = (id: any) => {
    APIUtils.callDelete(`api/client/delete/${id}`)
    .then(response => {
      if (response.status !== 200) {
        return console.error("Error Deleting Client");
      }
      removeClientFromList(response.data._id);
      setShowDeleteConfirmation(false);
    });
  }

  const addClientToList = (client: Client) => {
    let newList = [client, ...clientList];
    setClientList(newList);
  }

  const removeClientFromList = (id: any) => {
    let clientToRemove = clientList.findIndex((client) => {
      return client._id === id;
    });
    let newList = [...clientList.slice(0, clientToRemove), ...clientList.slice(clientToRemove + 1)];
    setClientList(newList);
  }

  const onClientClick = (client: ActiveClientType) => {
    setActiveClient(client);
    setShowDataModal(true);
  }

  useEffect(() => {
    if(showDataModal === false) {
      fetchClientList();
    }
  }, [showDataModal]);

  return(
    <>
      <ClientModal
        showClientModal={(show) => setShowClientModal(show)}
        addClient={(client) => addClientToList(client)}
        showModal={showClientModal}
      />
      <ClientDeleteModal 
        clientInfo={deleteClientInfo}
        showClientDeleteModal={(show) => setShowDeleteConfirmation(show)}
        deleteClient={(id: any) => deleteClient(id)}
        showModal={showDeleteConfirmation}
      />
      <DataModal
        activeClient={activeClient}
        showDataModal={(show) => setShowDataModal(show)}
        showModal={showDataModal}
      />
      <section id="AdminWrap">
        
        <Header />

        <ClientListing 
            clientList={clientList}
            onClientClick={(client: ActiveClientType) => onClientClick(client)}
            confirmDeleteClient={(id: any, clientName: string) => confirmDeleteClient(id, clientName)}
            showClientModal={() => setShowClientModal(true)}
        />

        {/* <div className="contentWrap">

          {clientList.length !== 0 ? (
            <div className="clientTable">
              <section className="titles">
                <article>Client</article>
                <article>ID</article>
                <article># Data Points</article>
                <article></article>
              </section>
              <div className="listing">
                {clientList.map((client: Client, index: number) => {
                  return (
                    <ClientListItem
                      {...client}
                      key={index}
                      delete={(id: Types.ObjectId, clientName: string) => confirmDeleteClient(id, clientName)}
                      onClientClick={(client: any) => onClientClick(client)}
                    />);
                })}
              </div>
            </div>
            ) : (
              <div className="alert">
                No clients created. Click the button to add new client.
              </div>
            )
          }

          <button className="addBtn" onClick={() => setShowClientModal(true)}>+ Add Client</button>

        </div> */}

      </section>
    </>
  );
}

export default Account;