import { useState, useEffect, useRef } from 'react'
import APIUtils from '../../utils/APIUtils';

import DashboardHeader from '../Header'
import UserModal from './UserModal';
import UserDeleteModal from './UserDeleteModal';
import UserListItem from './UserListItem';

const Users = () => {
    const fetchRef = useRef(false);

    const [showUserModal, setShowUserModal] = useState(false)
    const [showUserDeleteModal, setShowUserDeleteModal] = useState(false)
    const [selectedUser, setSelectedUser] = useState<any>({});
    const [userList, setUserList] = useState([])

    const fetchUserList = async () => {
        try {
            let res = await APIUtils.callGet('api/users')

            if (res.status !== 200) {
                console.log('Error retrieving users.')
            }

            setUserList(res.data)

        } catch(err) {
            console.error(err)
        }
    }

    const showDeleteUserModal = (user: any) => {
        setSelectedUser(user);
        setShowUserDeleteModal(true);
    }

    const deleteUser = async (id: string) => {
        try {
            let res = await APIUtils.callDelete(`api/user/${id}`)

            if (res.status !== 200) {
                console.log('Error retrieving users.')
            }

            fetchUserList();
            setShowUserDeleteModal(false);

        } catch(err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (fetchRef.current) return
        fetchRef.current = true
        
        fetchUserList()
    }, []);

    return (
        <>
        <UserDeleteModal
            user={selectedUser}
            hideUserDeleteModal={() => setShowUserDeleteModal(false)}
            deleteUser={(id: any) => deleteUser(id)}
            showModal={showUserDeleteModal}
        />
        <UserModal
            showUserModal={() => setShowUserModal(true)}
            hideUserModal={() => setShowUserModal(false)}
            addUser={() => fetchUserList()}
            showModal={showUserModal}
        />
        <section id="AdminWrap">
            <DashboardHeader />
            <div className="contentWrap">
                {userList.length !== 0 ? (
                    <div className="table users">
                         <section className="titles">
                            <article>Email</article>
                            <article>Status</article>
                            <article></article>
                        </section>
                        <div className="listing">
                            {userList.map((user: any, index: number) => {
                                return (
                                    <UserListItem 
                                        key={`user-${index}`}
                                        user={user}
                                        deleteUser={(user: any) => showDeleteUserModal(user)}
                                    />
                                )
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="alert">
                        No users found. Click the button to invite a new user.
                    </div>
                )}
                <button className="addBtn" onClick={() => setShowUserModal(true)}>+ Invite User</button>
            </div>
        </section>
        </>
    )
}

export default Users
