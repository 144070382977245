import { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import APIUtils from '../utils/APIUtils';
import NavUtils from '../utils/NavUtils';

import Login from './Login';
import Register from './Register';
import Clients from './Clients/Clients';
import Users from './Users/Users';

const App = () => {
    const verifyRef = useRef(false);

    const verifyUserSession = async () => {
        try {
            let res = await APIUtils.callGet('api/account/verify');

            if (
                res.status !== 200 ||
                typeof res !== 'object' ||  
                typeof res.data !== 'object'
            ) {
                const publicPages = ['/', '/register'];
                if (publicPages.includes(window.location.pathname)) {
                    return;
                }
                return NavUtils.redirectToRoot();
            }

            const adminPages = ['/users'];
            if (adminPages.includes(window.location.pathname)) {
                return;
            }
            
            return NavUtils.redirectToDashboard();
        } catch(err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (verifyRef.current) return;
        verifyRef.current = true;

        verifyUserSession();
    }, []);

    return (
        <Router>
            <Routes>
                <Route 
                    path="/" 
                    element={<Login />} 
                />
                <Route 
                    path="/register" 
                    element={<Register />} 
                />
                <Route
                    path="/dashboard"
                    element={<Clients />}
                />
                <Route
                    path="/users"
                    element={<Users />}
                />
            </Routes>
        </Router>
    )
}

export default App;
