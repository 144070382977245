import {useState, useEffect} from 'react';
import APIUtils from '../utils/APIUtils';
import NavUtils from '../utils/NavUtils';

import logoutIcon from '../assets/img/logout.svg';
import Logo from '../assets/img/logo.jpg';

const DashboardHeader = () => {
    const urlPath = window.location.pathname.replace('/','');
    const [activeNav, setActiveNav] = useState('dashboard');

    const logout = async () => {
        try {
            await APIUtils.callGet('api/account/logout')
            
            NavUtils.redirectToRoot();
        } catch(err) {
            console.error(err);
        }
	}

    const navClick = (page: string) => {
        if (page === 'clients') {
            NavUtils.redirectToDashboard();
        }
        if (page === 'users') {
            NavUtils.redirectToUsers();
        }
    }

    useEffect(() => {
        setActiveNav(urlPath);
    }, []);

    return (
        <header>
          <div className="headerContentWrap">
            <img className="clientLogo" src={Logo} alt="" />
            <nav>
                <button className={activeNav === 'dashboard' ? 'navBtn active' : 'navBtn'} onClick={() => navClick('clients')}>Clients</button>
                <button className={activeNav === 'users' ? 'navBtn active' : 'navBtn'} onClick={() => navClick('users')}>Users</button>
                <button className="logoutBtn" onClick={logout}>
                    Logout
                    <img src={logoutIcon} alt="" />
                </button>
            </nav>
          </div>
        </header>
    )
}

export default DashboardHeader
