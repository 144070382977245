import alert from '../../assets/img/alert.svg';

const UserDeleteModal = (props: {
  user: {
    id: string,
    email: string
  }
  hideUserDeleteModal: () => void
  deleteUser: (id: any) => void
  showModal: boolean
}) => {
  return (
    <>
    {props.showModal && (
      <div className="modal clientDeleteModal">
        <div className="modalWrap">
          <section className="contentWrap">
            <div className="image">
              <img src={alert} alt="Warning" />
              <span className="circle small"></span>
              <span className="circle large"></span>
            </div>
            <h2>Are you sure you want to delete "{props.user.email}"?</h2>
            <p>Deleting this user will permanentally remove their access to this admin.</p>
            <div className="btnGroup">
              <button className="cancelBtn" onClick={() => props.hideUserDeleteModal()}>Cancel</button>
              <button className="deleteBtn" onClick={(id: any) => props.deleteUser(props.user.id)}>Delete</button>
            </div>
          </section>
        </div>
      </div>
    )}
    </>
  )
}

export default UserDeleteModal;
